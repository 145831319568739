import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Inicio',
        requiresAuth: true 
      },
    },
    {
      path: '/rates',
      name: 'rates',
      component: () => import('@/views/Rates.vue'),
      meta: {
        pageTitle: 'Tarifas',
        requiresAuth: true
      },
    },
    {
       path: '/new-work-order/:order',
       name: 'newworkorder',
       component: () => import('@/views/NewWorkOrder.vue'),
       meta: {
        pageTitle: 'Nueva Orden Trabajo',
         requiresAuth: true 
       },
    },
    {
      path: '/work-orders/inProcess',
      name: 'workorders',
      component: () => import('@/views/WorkOrders.vue'),
      props: { type: 'InProcess'},
      meta: {
        pageTitle: 'OT en proceso',
        requiresAuth: true 
      },
    },
    {
      path: '/work-orders/budgeted',
      name: 'workorders-budgeted',
      props: { type: 'Budgeted'},
      component: () => import('@/views/WorkOrders.vue'),
      meta: {
        pageTitle: 'OT Presupuestado',
        requiresAuth: true 
      },
    },
    {
      path: '/work-orders/Nulls',
      name: 'workorders-nulls',
      component: () => import('@/views/WorkOrders.vue'),
      props: { type: 'Nulls'},
      meta: {
        pageTitle: 'OT Nulos',
        requiresAuth: true 
      },
    },
    // completadas
    {
      path: '/work-orders/Completed',
      name: 'workorders-completed',
      component: () => import('@/views/WorkOrders.vue'),
      props: { type: 'Completed'},
      meta: {
        pageTitle: 'OT Completadas',
        requiresAuth: true 
      },    
    },
    // oportunidades
    {
      path: '/work-orders/Oportunity',
      name: 'workorders-oportunities',
      component: () => import('@/views/WorkOrders.vue'),
      props: { type: 'Oportunity'},
      meta: {
        pageTitle: 'OT Oportunidades',
        requiresAuth: true 
      },    
    },
    
    {
      path: '/work-orders/Liquidar',
      name: 'workorders-pliquidar',
      component: () => import('@/views/WorkOrders.vue'),
      props: { type: 'Liquidar'},
      meta: {
        pageTitle: 'OT Liquidadas',
        requiresAuth: true 
      },
    },
    {
      path: '/work-order/:order',
      name: 'workorder',
      component: () => import('@/views/WorkOrder.vue'),
      meta: {
        requiresAuth: true 
      },
    },
    {
      path: '/create-budget/:order',
      name: 'createBudget',
      component: () => import('@/views/NewBudget.vue'),
      meta: {
        pageTitle: 'Crear presupuesto',
        requiresAuth: true 
      },
    },
    {
      path: '/budget/:budget',
      name: 'budget',
      component: () => import('@/views/Budget.vue'),
      meta: {
        pageTitle: 'Presupuesto',
        requiresAuth: true 
      },
    },
    {
      path: '/delivery/:delivery',
      name: 'delivery',
      component: () => import('@/views/Delivery.vue'),
      meta: {
        pageTitle: 'Albarán',
        requiresAuth: true 
      },
    },
    {
      path: '/create-delivery',
      name: 'newDelivery',
      component: () => import('@/views/NewDelivery.vue'),
      meta: {
        pageTitle: 'Crear albarán',
        requiresAuth: true 
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/profile.vue'),
      meta: {
        pageTitle: 'Perfil de Usuario',
        requiresAuth: true 
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach(async (to, from, next) => {
  await store.dispatch("auth/getUser")
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/currentUser']) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
